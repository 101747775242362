
import { defineComponent, getCurrentInstance, reactive, ref } from "vue";

export default defineComponent({
  name: "Login",

  setup() {
    const { proxy }: any = getCurrentInstance();

    // 轮播图
    const images = reactive([
      "https://file.xdclass.net/video/2021/1-lbt/VIP/vip1299.png",
      "https://file.xdclass.net/video/2021/62-paas/lbt-paas.png",
      "https://file.xdclass.net/video/2021/59-Postman/59lbt.png",
    ]);
    // 登录表单
    const username = ref("");
    const password = ref("");
    const loading = ref(false);

    // 登录
    async function login() {
      console.log("login");
      loading.value = true;
      try {
        const ret = await proxy.$http.post("/api/login", {
          username: username.value,
          password: password.value,
        });
        console.log(ret);
        if (ret.data.code === 0) {
          sessionStorage.setItem("token", ret.data.data.token);
          proxy.$toast.success("登录成功");
          proxy.$router.push({
            path: "/nav/index",
          });
        } else {
          proxy.$toast.fail(ret.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    }

    // 清空
    function clear() {
      username.value = "";
      password.value = "";
    }

    return { images, username, password, loading, login, clear };
  },
});
